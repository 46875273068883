import {Link, useNavigate} from 'react-router-dom';
import {CategoryAndBots} from '../../api/apiHelper';
import {MainContainer} from '../Main/MainContainer';
import styles from './styles.Category.module.css';
import {useContext, useEffect, useState} from 'react';
import {chatStorage} from '../../chatStore/ChatStorage';
import {BotData, BotDataWithLastMessage} from '../../api/types';
import {AppContext} from '../../App';
import {webApi} from '../../api/webApi';

type Props = {
	category: CategoryAndBots;
};

export const VisitedCategory = ({category}: Props) => {
	const {data} = category;
	const [botsWithMessage, setBotsWithMessage] = useState<
		BotDataWithLastMessage[]
	>([]);
	const {visitedBots, loading, user} = useContext(AppContext);
	const [isLoading, setIsLoading] = useState(false);

	const navigate = useNavigate();
	const handleTileClick = (bot: BotData) => {
		navigate(`/bot_${bot.id}/chat`);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);

				const visitedIds = chatStorage.getChatVisited();
				if (visitedIds.length === 0) {
					navigate('/');
				}
				const {data: botsData} = await webApi.getBotsByIds(visitedIds);
				const botsInfo: Array<BotData & {lastMessage: string}> = botsData.map(
					(bot) => {
						return {
							...bot,
							lastMessage: (chatStorage.getLastMessage(bot.id) || '') as string,
						};
					}
				);
				botsInfo.sort(
					(a, b) =>
						visitedIds.indexOf(a.id.toString()) -
						visitedIds.indexOf(b.id.toString())
				);
				setBotsWithMessage(botsInfo);
			} catch (e) {
				console.error(e);
			} finally {
				setIsLoading(false);
			}
		};

		if (!user || user.isAnon) {
			fetchData();
		} else {
			if (visitedBots.length === 0) {
				navigate('/');
			}

			setBotsWithMessage(visitedBots);
		}
	}, []);

	return (
		<MainContainer
			headerContent={
				<div className={styles.headerContent}>
					<Link to="/" className={styles.backLink} title="Home page">
						Home
					</Link>
					<p>{'>'}</p>
					{data.attributes.name}
				</div>
			}
			isLoading={loading || isLoading}
		>
			<div className={styles.visited}>
				<h2 className={styles.visitedHeader}>{data.attributes.name}</h2>
				{botsWithMessage.map((bot) => (
					<div
						key={bot.id}
						className={styles.visitedRow}
						onClick={() => handleTileClick(bot)}
					>
						<img
							src={bot.attributes.avatarUrl}
							alt="bot avatar"
							className={styles.visitedAvatar}
						/>
						<div className={styles.visitedText}>
							<p className={styles.visitedName}>{bot.attributes.name}</p>
							{bot.last_message && (
								<p className={styles.visitedMessage}>
									{bot.last_message?.text}
								</p>
							)}
						</div>
					</div>
				))}
			</div>
		</MainContainer>
	);
};
