import {useContext, useEffect} from 'react';
import './BotPage.css';
import {BotData} from '../../../../api/types';
import {AppContext} from '../../../../App';
import {DesktopBotPage} from './DesktopBotPage';
import {MobileBotPage} from './MobileBotPage';

import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';
import {ChatVoteParams, webEventsApi} from '../../../../amplitude/webEvents';
import {usersApi} from '../../../../api/usersApi/usersApi';
import {webApi} from '../../../../api/webApi';
import {chatStorage} from '../../../../chatStore/ChatStorage';
import {api, MediaResponse, Message} from '../../../../common-lib/src/api/Api';
import {
	VoteMessageReqBody,
	LlmData,
} from '../../../../common-lib/src/api/Api.types';
import {
	MessageVote,
	VoteMessageData,
	UserActions,
	Actions,
} from '../../../../common-lib/src/components';
import {isIOS} from '../../../../common-lib/src/utils';
import {useBotPage} from './hooks/useBotPage';
import {useBotPageMeta} from './hooks/useBotPageMeta';
import {useChat} from './hooks/useChat';
import {getChatType, checkAcessLevel} from './utils';
import {DeleteMessageDialog} from './DeleteMessageDialog/DeleteMessageDialog';

type Props = {
	bot: BotData;
};

export const BotPage = ({bot}: Props) => {
	const {enqueueSnackbar} = useSnackbar();
	const navigate = useNavigate();
	const {isMobile} = useContext(AppContext);

	const {
		inputHeight,
		chatRef,
		inputRef,
		isTyping,
		context,
		handleInputChange,
		handleKeyDown,
		scrollChat,
		inputValue,
		handleSendMessage,
		handleRetry,
		clearChat,
		deleteChat,
		deleteBot,
		deleteMessage,
		voteMessage,
		lastResponse,
		isInitLoading,
		isChatLimited,
		limitTimeLeft,
		handleEdit,
		handlePlayAudio,
		handleStopAudio,
		isSendError,
		handleResend,
		shouldShowRetryFeedback,
		handleCloseRetryFeedback,
		shouldShowVoteNotification,
		handleCloseVoteNotification,
		availableLlms,
		selectedLlm,
		setSelectedLlm,
		handleMagic,
		isMuted,
		handleSetIsMuted,
		isGlobalLoading,
		setIsGlobalLoading,
		handleCopy,
		handleUpdateMessageInContextByMediaId,
		pagination,
	} = useChat(bot);
	const {
		user,
		handleLoginOrPaywall,
		setPaywallType,
		visitedBots,
		setVisitedBots,
	} = useContext(AppContext);

	const {
		handleGoBack,
		handleShareClick,
		isSharingOpened,
		setIsSharingOpened,
		isOtherActionsOpened,
		setIsOtherActionsOpened,
		handleOpen,
		handleEditClick,
		setIsFeedbackOpened,
		isFeedbackOpened,
		handleSubmitReport,
		handleGoProfile,
		handleDeleteConfirmation,
		handleDeleteConfirmationClose,
		deleteConfirmationMessage,
		showDeleteConfirmation,
	} = useBotPage(bot, true);

	useBotPageMeta(bot);

	const handleScrollBody = (e: Event) => {
		// @ts-ignore
		if (!e.target.alt) {
			e.preventDefault();
			inputRef.current?.blur();
		}
	};

	const handleFocus = () => {
		scrollChat();
		if (isIOS) {
			setTimeout(() => {
				document.addEventListener('touchstart', handleScrollBody);
			}, 100);
		}
	};

	const handleBlur = () => {
		if (isIOS) {
			document.removeEventListener('touchstart', handleScrollBody);
		}
	};

	useEffect(() => {
		document.removeEventListener('touchstart', handleScrollBody);
	}, []);

	const handleClearChat = () => {
		if (isInitLoading) {
			return;
		}
		clearChat();
		setIsOtherActionsOpened(false);
		api.removeMemory(bot.id);
	};

	const afterDeleteChat = () => {
		setVisitedBots(visitedBots.filter((b) => b.id !== bot.id));
		chatStorage.deleteChat(bot.id.toString());
		setIsOtherActionsOpened(false);
		api.removeMemory(bot.id);
		navigate('/');
	};

	const handleDeleteChat = async () => {
		await deleteChat();
		afterDeleteChat();
	};

	const handleDeleteBot = async () => {
		await deleteBot();
		afterDeleteChat();
	};

	const extractData = (message: Message) => {
		const lastMessage = message || context.at(-1);
		if (!lastResponse && !lastMessage) {
			return null;
		}
		const data: VoteMessageReqBody = {
			response: lastResponse?.response || lastMessage?.message || '',
			context: context.slice(-20),
			contains_media:
				!!lastResponse?.base64_image ||
				!!lastMessage?.image ||
				!!lastMessage?.video,
			bot_name: bot.attributes.name,
			strapi_bot_id: bot.id.toString(),
			split: lastResponse?.split || lastMessage?.split || '',
			reaction: 'upvote' as MessageVote,
			prev_responses: lastMessage?.prevResponses || undefined,
			bot_appearance: bot.attributes.appearance || null,
			bot_description: bot.attributes.description || null,
			media_url:
				lastResponse?.media_response?.media_url ||
				lastMessage?.media_response?.media_url ||
				null,
			bot_pronoun: bot.attributes.pronoun || undefined,
			user_name: user?.personaName || undefined,
			user_pronoun: user?.personaPronouns || undefined,
		};

		const amplitudeDat: ChatVoteParams = {
			is_photo: !!lastResponse?.base64_image || !!lastMessage?.image,
			bot_name: data.bot_name,
			chat_id: bot.id,
			chat_type: bot.attributes.firebaseUserId ? 'custom' : 'default',
			split_response: data.split,
		};

		return {
			data,
			amplitudeDat,
		};
	};

	const saveVoteToMessage = async (vote: MessageVote, message: Message) => {
		const upvotes: MessageVote[] = ['better', 'upvote'];
		const downvotes: MessageVote[] = ['worse', 'downvote'];
		let reaction = null;
		if (upvotes.includes(vote)) {
			reaction = 'upvote';
		}
		if (downvotes.includes(vote)) {
			reaction = 'downvote';
		}
		if (!reaction) {
			console.error('Invalid vote type');
			return;
		}

		chatStorage.setReaction(bot.id, message, reaction);
		await voteMessage(message, reaction);
	};

	const handleVote = async ({vote, description, message}: VoteMessageData) => {
		if (user?.isAnon) {
			handleLoginOrPaywall();
			return false;
		}
		const info = extractData(message);
		if (!info) {
			return false;
		}
		const {amplitudeDat} = info;
		const data = info.data as VoteMessageReqBody;

		data.reaction = vote;
		if (description) {
			data.reaction_description = description;
		}
		try {
			if (!data.split) {
				return;
			}

			if (vote === 'upvote' || vote === 'downvote') {
				handleCloseVoteNotification();
			}

			await api.voteMessage(data);

			if (vote === 'upvote') {
				webEventsApi.messageUpvoted(amplitudeDat);
			}
			if (vote === 'downvote') {
				if (description) {
					//@ts-ignore
					amplitudeDat.downvote_type = description;
				}
				webEventsApi.messageDownvoted(amplitudeDat);
			}

			if (vote === 'better' || vote === 'worse' || vote === 'same') {
				switch (vote) {
					case 'better':
						webEventsApi.messageBetter(amplitudeDat);
						break;
					case 'worse':
						webEventsApi.messageWorse(amplitudeDat);
						break;
					case 'same':
						webEventsApi.messageSame(amplitudeDat);
						break;
				}
			}

			await saveVoteToMessage(vote, message);
			return true;
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Something went wrong', {variant: 'error'});
		}
	};
	const handleLike = (message: Message) => {
		return handleVote({vote: 'upvote', message});
	};
	const handleDislike = (data: {
		text?: string;
		email?: string;
		description?: string;
		message: Message;
	}) => {
		handleVote({
			vote: 'downvote',
			description: data?.description,
			message: data.message,
		});

		if (data?.text) {
			const info = extractData(data.message);
			if (!info) {
				return;
			}
			const rd = info.data as VoteMessageReqBody & {text_feedback: string};
			rd.text_feedback = data.text;
			rd.reaction = 'downvote';
			api.addFeedback(rd);
		}
		if (data?.text) {
			webApi.userFeedback({feedback: data.text, email: data.email || ''});
		}
	};

	const handleDeleteConfirmationShow = (message: Message) => {
		handleDeleteConfirmation(message);
	};

	const handleDeleteConfirmationSubmit = async () => {
		if (!deleteConfirmationMessage) {
			return;
		}
		handleDeleteConfirmationClose();
		setIsGlobalLoading(true);
		try {
			await handleDeleteMessage(deleteConfirmationMessage);
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Something went wrong', {variant: 'error'});
		} finally {
			setIsGlobalLoading(false);
		}
	};

	const handleDeleteMessage = async (message: Message) => {
		await deleteMessage(message);
		webEventsApi.messageDeleted({
			chat_id: bot.id,
			chat_type: getChatType(bot),
			bot_name: bot.attributes.name,
			split_response: message.split,
		});
	};

	const handleRetryClick = async (message: Message) => {
		await handleRetry(message);
		const info = extractData(message);
		if (!info) {
			return;
		}
		webEventsApi.responseWasRegenerated(info.amplitudeDat);
	};
	const handleEditSubmit = (
		message: Message,
		text: string
	): Promise<boolean> => {
		return handleEdit(message, text);
	};

	const handleUnblur = async (message: Message) => {
		if (user?.isPaid) {
			if (!message.media_response?.media_id) {
				enqueueSnackbar('Sorry, this media can not be restored', {
					variant: 'error',
				});
				return;
			}
			try {
				let mediaResponse: MediaResponse;
				if (message.media_response?.media_type === 'video') {
					mediaResponse = await api.getVideoMessage(
						message.media_response?.media_id
					);
				} else {
					mediaResponse = await api.getMediaMessage(
						message.media_response?.media_id,
						bot.attributes.avatarUrl
					);
				}
				const findedMessage = context.find(
					(m) => m.media_response?.media_id === message.media_response?.media_id
				);
				if (findedMessage) {
					findedMessage.media_response = mediaResponse;
					findedMessage.blured = false;
					if (mediaResponse.media_type === 'video') {
						findedMessage.video = mediaResponse.media_url;
					}
					if (mediaResponse.media_type === 'image') {
						findedMessage.image = mediaResponse.media_url;
					}
					handleUpdateMessageInContextByMediaId(findedMessage);
				}

				if (user && message.id) {
					await usersApi.updateMessage(
						bot.attributes.firebaseChatId,
						message.id,
						{
							mediaResponse: {
								mediaUrl: mediaResponse.media_url,
								mediaId: mediaResponse.media_id,
								mediaType: mediaResponse.media_type,
								mediaResolution: mediaResponse.media_resolution,
							},
							text: message.message,
						}
					);
				}
			} catch (e) {
				console.error(e);
				enqueueSnackbar('Failed to load photo. Try again later', {
					variant: 'error',
				});
			}
		} else {
			handleLoginOrPaywall();
		}
	};

	const handleSelectLlm = (llm: LlmData | null) => {
		if (!llm) {
			return;
		}
		if (checkAcessLevel(user, llm.access_level)) {
			setSelectedLlm(llm);
			return;
		}

		setPaywallType(llm.access_level === 'ultra' ? 'ultra' : 'regular');
		handleLoginOrPaywall();
	};

	const userActions: UserActions = {
		onErrorClick: isSendError ? handleResend : undefined,
	};

	const isPremium = !!user?.isPaid;

	const actions: Actions = {
		like: handleLike,
		dislike: handleDislike,
		disabledLike: user?.isAnon,
		retry: isChatLimited ? undefined : handleRetryClick,
		unblur: handleUnblur,
		hasPremium: isPremium,
		edit: handleEditSubmit,
		isEditable: true,
		scrollChat,
		playAudio: handlePlayAudio,
		stopAudio: handleStopAudio,
		shouldShowRetryFeedback,
		handleCloseRetryFeedback,
		vote: user && !user.isAnon ? handleVote : handleLoginOrPaywall,
		shouldShowVoteNotification,
		handleCloseVoteNotification: handleCloseVoteNotification,
		handleDeleteMessage: handleDeleteConfirmationShow,
		magic: bot.attributes.generatePhotos ? handleMagic : undefined,
		muted: isMuted,
		copy: handleCopy,
	};

	const botProps = {
		handleGoBack,
		bot,
		handleShareClick,
		handleOpen,
		isOtherActionsOpened,
		handleClearChat,
		handleDeleteBot,
		handleDeleteChat,
		setIsFeedbackOpened,
		chatRef,
		isTyping,
		context,
		inputRef,
		inputHeight,
		inputValue,
		handleInputChange,
		handleKeyDown,
		handleSendMessage,
		isSharingOpened,
		setIsSharingOpened,
		isFeedbackOpened,
		handleSubmitReport,
		handleFocus,
		handleBlur,
		actions,
		isChatLimited,
		limitTimeLeft,
		handleEditBot: handleEditClick,
		handleGoProfile,
		userActions,
		availableLlms,
		selectedLlm,
		handleSelectLlm,
		isMuted,
		handleSetIsMuted,
		isGlobalLoading,
		pagination,
	};

	return (
		<>
			{isMobile ? (
				<MobileBotPage {...botProps} />
			) : (
				<DesktopBotPage {...botProps} />
			)}
			{showDeleteConfirmation && (
				<DeleteMessageDialog
					open={showDeleteConfirmation}
					onClose={handleDeleteConfirmationClose}
					onSubmit={handleDeleteConfirmationSubmit}
				/>
			)}
		</>
	);
};
