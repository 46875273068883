import {
	useState,
	useRef,
	useCallback,
	useEffect,
	useContext,
	useLayoutEffect,
} from 'react';
import {BotData} from '../../../../../api/types';
import {useSnackbar} from 'notistack';

import {webEventsApi} from '../../../../../amplitude/webEvents';
import {usersApi} from '../../../../../api/usersApi/usersApi';
import {UserInfo} from '../../../../../api/usersApi/usersApi.types';
import {FrbConverter} from '../../../../../api/usersApi/usersApi.utils';
import {AppContext} from '../../../../../App';
import {TimeLimitInfo, chatStorage} from '../../../../../chatStore/ChatStorage';
import {
	Message,
	api,
	ChatResponse,
} from '../../../../../common-lib/src/api/Api';
import {LlmData} from '../../../../../common-lib/src/api/Api.types';
import {convertBase64ToImage} from '../../../../../common-lib/src/utils';
import {
	DEFAULT_LIMIT_INFO,
	SHOWN_VOTE_NOTIFICATION_KEY,
	inputInitialHeight,
	headerHeight,
	magicConst,
	SHOW_VOTE_NOTIFICATION_COUNT,
	CONTEXT_SLICE_LENGTH,
	inputMaxHeight,
} from '../constants';
import {
	getChatType,
	prepareMessageFromResponse,
	checkAcessLevel,
	replaceMultipleNewlines,
	getSplit,
} from '../utils';
import {webApi} from '../../../../../api/webApi';

let controller = new AbortController();
let signal = controller.signal;
let isInitResize = true;
let isMultipleMessages = false;

// Fix to stop audio when leave page
let audioForce: HTMLAudioElement | null = null;

const chatV3 = (
	user: UserInfo | null,
	{
		bot,
		context,
		skipLimit,
		prevResponses,
		isRetry,
		llm,
	}: {
		bot: BotData;
		context: Message[];
		skipLimit: boolean;
		prevResponses: string[];
		isRetry?: boolean;
		llm?: LlmData | null;
	}
) => {
	controller.abort();
	controller = new AbortController();
	signal = controller.signal;

	return api.chatfromDigitalHumans(
		bot.attributes.name,
		context,
		true,
		{
			lipsync: false,
			send_photo: bot.attributes.generatePhotos ?? true,
			strapi_bot_id: bot.id,
			persona_facts: bot.attributes.personaFacts.map((f) => f.content),
			response_emotion: bot.attributes.emotion || undefined,
			bot_pronoun: bot.attributes.pronoun || undefined,
			predefined: bot.attributes.firebaseUserId === null,
			is_retry: !!isRetry,
			access_level: user?.accessLevel || ('basic' as const),
			prev_responses: prevResponses.length
				? prevResponses.filter((r) => r.length)
				: undefined,
			voice_name: bot.attributes.voice?.data?.attributes.name || undefined,
			ultra_llm_id: llm?.id || undefined,
			media_retry_support: true,
		},
		signal
	);
};

const saveMessageToFrb = async (
	bot: BotData,
	user: UserInfo,
	message: Message
): Promise<Message> => {
	if (!user) {
		return message;
	}

	const result = await usersApi.createMessage(
		bot.attributes.firebaseChatId,
		FrbConverter.toFrbMessage({
			botId: bot.id,
			botName: bot.attributes.name,
			message,
			userId: user.sourceSystemId,
		})
	);
	return FrbConverter.toMessage(result);
};

const abortFetch = () => {
	controller.abort();
	controller = new AbortController();
	signal = controller.signal;
};

const DEFAULT_PAGE_SIZE = 50;
const usePagination = (bot: BotData) => {
	const [page, setPage] = useState(0);
	const [hasMore, setHasMore] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setPage(0);
	}, [bot]);

	return {
		page,
		setPage,
		hasMore,
		setHasMore,
		loading,
		setLoading,
		pageSize: DEFAULT_PAGE_SIZE,
	};
};

export const useChat = (bot: BotData) => {
	const [inputValue, setInputValue] = useState<string>('');
	const [context, setContext] = useState<Message[]>([]);
	const [isTyping, setIsTyping] = useState(true);
	const [lastResponse, setLastResponse] = useState<ChatResponse | null>(null);
	const messagesCount = useRef(0);
	const [isInitLoading, setIsInitLoading] = useState(false);
	const [isGlobalLoading, setIsGlobalLoading] = useState(false);
	const {
		user,
		setIsLimitModalOpened,
		setIsLoginOpened,
		handleLoginOrPaywall,
		isLoadingUser,
	} = useContext(AppContext);
	const [isChatLimited, setIsChatLimited] = useState(false);
	const [limitTimeLeft, setLimitTimeLeft] =
		useState<TimeLimitInfo>(DEFAULT_LIMIT_INFO);
	const [isPlayingAudio, setIsPlayingAudio] = useState(false);
	const [audio, setAudio] = useState<HTMLAudioElement | null>(null);
	const [isSendError, setIsSendError] = useState(false);
	const [countMessagesWithoutVote, setCountMessagesWithoutVote] = useState(0);
	const [shouldShowRetryFeedback, setShouldShowRetryFeedback] = useState(false);
	const [wasShownVoteNotification, setWasShownVoteNotification] = useState(
		localStorage.getItem(SHOWN_VOTE_NOTIFICATION_KEY) === 'true'
	);
	const [inputHeight, sih] = useState(inputInitialHeight);
	const [chatHeight, setChatHeight] = useState(
		window.innerHeight - headerHeight - inputInitialHeight - magicConst
	);
	const noScrollRef = useRef<NodeJS.Timer | null>(null);
	const [availableLlms, setAvailableLlms] = useState<LlmData[]>([]);
	const [selectedLlm, setSelectedLlm] = useState<LlmData | null>(null);
	const [isMuted, setIsMuted] = useState(true);

	const needRefresh = useRef(false);
	const chatRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLTextAreaElement>(null);
	const prevResponses = useRef<string[]>([]);
	const initRef = useRef(false);

	const pagination = usePagination(bot);

	//Just to fix playing init message
	const [playInitMessage, setPlayInitMessage] = useState<Message | null>(null);

	const {enqueueSnackbar} = useSnackbar();

	const shouldShowVoteNotification =
		countMessagesWithoutVote === SHOW_VOTE_NOTIFICATION_COUNT &&
		!wasShownVoteNotification;
	const handleCloseVoteNotification = () => {
		setWasShownVoteNotification(true);
		localStorage.setItem(SHOWN_VOTE_NOTIFICATION_KEY, 'true');
	};

	const handleCloseRetryFeedback = () => {
		setShouldShowRetryFeedback(false);
	};

	const handleOpenRetryFeedback = () => {
		setShouldShowRetryFeedback(true);
	};

	const setInputHeight = (height: number) => {
		inputRef.current?.style.setProperty('height', 'auto');
		inputRef.current?.style.setProperty('height', `${height}px`);
		sih(height);
	};

	useEffect(() => {
		setTimeout(() => {
			scrollChat();
		}, 100);
	}, [bot]);

	const fetchChatFronFrb = async (page: number) => {
		pagination.setPage(page);
		pagination.setHasMore(false);
		const chatsFromFrb = await usersApi
			.getChatHistory(bot.attributes.firebaseChatId, {
				page,
				pageSize: pagination.pageSize,
			})
			.catch((e) => {
				enqueueSnackbar('Failed to get chat history', {variant: 'error'});
				console.error(e);
				return {
					messages: [],
					id: bot.attributes.firebaseChatId,
					page: 0,
					total: 0,
				};
			});

		setTimeout(() => {
			const hasMore =
				chatsFromFrb.total > (chatsFromFrb.page + 1) * pagination.pageSize;
			pagination.setHasMore(hasMore);
		}, 500);

		const converted = FrbConverter.toContext(chatsFromFrb).reverse();
		return converted;
	};

	const handleLoadMoreChat = async () => {
		pagination.setLoading(true);
		const newPage = pagination.page + 1;
		pagination.setPage(newPage);
		const newMessages = await fetchChatFronFrb(newPage);
		pagination.setLoading(false);
		handleSetContext([...newMessages, ...context], true);
		setTimeout(() => {
			const lastMessage = newMessages[newMessages.length - 1];
			if (!lastMessage?.timestamp || !chatRef.current) {
				return;
			}
			const lastMessageEl = document.getElementById(
				`message_${lastMessage.timestamp}`
			);
			if (!lastMessageEl) {
				return;
			}
			lastMessageEl?.scrollIntoView({
				behavior: 'auto',
				block: 'center',
			});
		}, 100);
	};

	const fetchInitChat = useCallback(async () => {
		if (isLoadingUser) {
			return;
		}
		setIsSendError(false);
		initRef.current = true;
		try {
			let savedContext = [] as Message[];
			if (user) {
				savedContext = await fetchChatFronFrb(0);
			} else {
				savedContext = chatStorage.get(bot.id);
			}

			if (savedContext && savedContext.length) {
				const contextWithImages = savedContext.map((message: Message) => {
					if (message.base64_image) {
						const image = convertBase64ToImage(message);
						if (image) {
							message.image = image;
						}
					}
					return message;
				});

				const lastMessage = contextWithImages[contextWithImages.length - 1];
				const isFromUser = lastMessage.turn === 'user';

				if (isFromUser) {
					contextWithImages.pop();
				}

				handleSetContext(contextWithImages);
				if (isFromUser) {
					setIsTyping(true);
					handleSendMessage(lastMessage.message, contextWithImages);
				}

				setIsTyping(false);
				return;
			} else {
				chatStorage.setMessages(bot.id, []);
			}

			setIsTyping(true);
			setIsInitLoading(true);

			const newContext = [] as Message[];
			const res = await chatV3(user, {
				bot,
				context: newContext,
				prevResponses: [],
				skipLimit: true,
				llm: selectedLlm,
			});

			webEventsApi.botSentGreeting({
				chat_id: bot.id,
				bot_name: bot.attributes.name,
				chat_type: getChatType(bot),
				is_photo: !!res.base64_image,
				split_response: res.split,
			});

			let message = prepareMessageFromResponse(res);

			if (message && !message.video) {
				setPlayInitMessage(message);
			}

			if (user) {
				message = await saveMessageToFrb(bot, user, message);
			}

			setLastResponse(res);
			newContext.push(message);
			handleSetContext(newContext);
			setIsTyping(false);
		} catch (err: any) {
			console.error(err);
			setIsTyping(false);
			setIsSendError(true);
			enqueueSnackbar('Failed to get chat history', {variant: 'error'});
		} finally {
			setIsInitLoading(false);
		}
	}, [bot, user, isLoadingUser]);

	useEffect(() => {
		if (playInitMessage) {
			handlePlayAudio(playInitMessage);
		}
	}, [playInitMessage]);

	useEffect(() => {
		const fetchLlms = async () => {
			const accessLevel = user?.accessLevel || 'basic';
			const llms = await api.getLlmList(accessLevel);

			if (llms?.ultra_llms) {
				setAvailableLlms(
					llms.ultra_llms.map((llm) => {
						return {
							...llm,
							available: checkAcessLevel(user, llm.access_level),
						};
					}) || []
				);
			}
		};

		fetchLlms();

		if (user?.isPaid && localStorage.getItem('isMuted') === 'false') {
			setIsMuted(false);
		}
	}, [user]);

	useEffect(() => {
		fetchInitChat();
		const timer = setInterval(() => {
			const isChatLimitedNew = chatStorage.isLimitReached() && !user?.isPaid;

			const limitTime = chatStorage.limitTimeLeft();
			if (isChatLimitedNew !== isChatLimited) {
				setIsChatLimited(isChatLimitedNew);
				setLimitTimeLeft(limitTime);
			}
			if (isChatLimited || isChatLimitedNew) {
				setLimitTimeLeft(limitTime);
			}
			if (
				(limitTime.sec === 0 &&
					limitTime.minutes === 0 &&
					limitTime.hours === 0 &&
					isChatLimited) ||
				user?.isPaid
			) {
				setIsChatLimited(false);
			}
		}, 1000);

		return () => {
			clearInterval(timer);
		};
	}, [bot, user, isChatLimited]);

	const focusInput = (withScroll = true) => {
		const inputEl = document.getElementById('chat-input');
		if (withScroll && !noScrollRef.current) {
			inputEl?.scrollIntoView({behavior: 'auto', block: 'end'});
		}
		inputEl?.focus({preventScroll: true});
	};

	const handleUpdateMessageInContextByMediaId = (message: Message) => {
		const updatedContext = context.map((m) => {
			if (m.media_response?.media_id === message.media_response?.media_id) {
				return message;
			}
			return m;
		});
		handleSetContext(updatedContext, true);
	};

	const handleSetContext = (newContext: Message[], noScroll?: boolean) => {
		if (noScroll) {
			if (noScrollRef.current) {
				clearTimeout(noScrollRef.current);
			}
			noScrollRef.current = setTimeout(() => {
				noScrollRef.current = null;
			}, 1000);
		}

		setContext(newContext);
	};

	const handleSetIsMutedHandler = (isMuted: boolean) => {
		if (isMuted) {
			resetAudio();
		}
		if (user?.isPaid) {
			setIsMuted(isMuted);
			localStorage.setItem('isMuted', isMuted.toString());
		} else {
			handleLoginOrPaywall();
		}
	};

	//DO NOT USE WITHOUT handleRetry
	const handleRetryMagic = async (message: Message) => {
		if (!message.id) {
			return;
		}
		chatStorage.message();

		const messageInContextIndex = context.findIndex(
			(m) => m.message === message.message && m.id === message.id
		);
		if (!messageInContextIndex) {
			return false;
		}

		const prevMessage = context[messageInContextIndex - 1];
		if (!prevMessage) {
			return false;
		}

		const copy = context.slice(0, messageInContextIndex);
		handleSetContext(copy, true);

		handleMagic(prevMessage, {
			prevContext: copy,
			prevImageMessage: context[messageInContextIndex],
		});
	};

	const handleRetry = async (message: Message) => {
		if (!user || user.isAnon) {
			setIsLoginOpened(true);
			return;
		}

		resetAudio();
		const isOnlyMediaMessage =
			(message.image || message.video) && !message.message;

		if (isOnlyMediaMessage) {
			handleRetryMagic(message);
			return;
		}

		chatStorage.message();

		const copy = context.slice();

		const messageInContext = copy.find(
			(m) => m.message === message.message && m.id === message.id
		);
		if (!messageInContext) {
			return false;
		}

		if (messageInContext.message) {
			prevResponses.current.push(messageInContext.message);
		}

		const index = copy.findIndex(
			(m) => m.message === message.message && m.id === message.id
		);

		const isLast = index === copy.length - 1;

		const contextForRequest = copy.slice(0, index).slice(CONTEXT_SLICE_LENGTH);

		handleSetContext(copy, true);

		await fetchRetryMessage(contextForRequest, copy, {index, isLast, message});

		if (isLast) {
			setTimeout(() => handleOpenRetryFeedback(), 0);
		}

		if (chatStorage.isLimitReached() && !user?.isPaid) {
			setIsChatLimited(true);
			setIsLimitModalOpened(true);
		}

		if (user?.isAnon && chatStorage.isWithoutAuthLimitReached()) {
			handleLoginOrPaywall();
		}
	};

	const handleResend = async () => {
		if (!user) {
			setIsLoginOpened(true);
			return;
		}

		const copy = context.slice();
		const lastmessage = copy[copy.length - 1];

		if (lastmessage?.turn === 'bot') {
			console.error('Resend user message: last message from bot');
			return;
		}

		const contextForRequest = copy.slice(CONTEXT_SLICE_LENGTH);

		handleSetContext(copy, true);

		await fetchMessage(contextForRequest, copy, {isRetry: false});
	};

	const handleEdit = async (
		message: Message,
		text: string
	): Promise<boolean> => {
		if (!user || user.isAnon) {
			handleLoginOrPaywall();
			return false;
		}
		const isValid = !!(await api.validateMessageText(text))
			?.is_inappropriate_message;

		if (isValid) {
			return false;
		}

		const copy = context.slice();

		const messageInContext = copy.find((m) => m.message === message.message);
		if (!messageInContext) {
			return false;
		}

		const isLastMessage =
			copy.findIndex(
				(m) => m.id === message.id && m.message === message.message
			) ===
			copy.length - 1;

		const newMessage = {...messageInContext, message: text, edited: true};

		if (message.id) {
			await usersApi.updateMessage(bot.attributes.firebaseChatId, message.id, {
				text,
				isLastMessage,
				mediaResponse: newMessage.media_response
					? {
							mediaUrl: newMessage.media_response.media_url,
							mediaId: newMessage.media_response.media_id,
							mediaType: newMessage.media_response.media_type,
							mediaResolution: newMessage.media_response.media_resolution,
					  }
					: undefined,
			});
		}

		const index = copy.findIndex((m) => m.message === message.message);
		copy[index] = newMessage;

		if (messageInContext.split) {
			api.messageEdited({
				context: copy,
				prev_context: context,
				contains_media: !!messageInContext.media_response?.media_id,
				media_url: messageInContext.media_response?.media_url || '',
				bot_name: bot.attributes.name,
				bot_pronoun: bot.attributes.pronoun,
				bot_description: bot.attributes.description,
				bot_appearance: bot.attributes.appearance,
				strapi_bot_id: bot.id.toString(),
				user_id: api.getUserId(),
				split: messageInContext.split,
			});
		}

		handleSetContext(copy, true);

		webEventsApi.messageEdited({
			chat_id: bot.id,
			chat_type: getChatType(bot),
			bot_name: bot.attributes.name,
			split_response: messageInContext.split,
		});

		return true;
	};

	const fetchRetryMessage = async (
		contextForRequest: Message[],
		newContext: Message[],
		{index, isLast, message}: {index: number; isLast: boolean; message: Message}
	) => {
		try {
			if (isLast) {
				setIsTyping(true);
			}

			const res = await chatV3(user, {
				bot,
				context: contextForRequest,
				skipLimit: false,
				prevResponses: prevResponses.current,
				llm: selectedLlm,
			});
			messagesCount.current++;
			increaseCountMessagesWithoutVote();

			if (!res.response && isMultipleMessages) {
				return;
			}

			const messageResponse = prepareMessageFromResponse(res);
			if (prevResponses.current?.length) {
				messageResponse.prevResponses = prevResponses.current;
			}
			if (message.id) {
				messageResponse.id = message.id;
			}

			if (!isMuted && message && !message.video) {
				await handlePlayAudio(messageResponse);
				setIsTyping(false);
			}

			newContext[index] = messageResponse;

			if (user && messageResponse.id) {
				const mediaResponse = messageResponse.media_response
					? {
							mediaUrl: messageResponse.media_response.media_url,
							mediaId: messageResponse.media_response.media_id,
							mediaType: messageResponse.media_response.media_type,
							mediaResolution: messageResponse.media_response.media_resolution,
					  }
					: undefined;
				await usersApi.updateMessage(
					bot.attributes.firebaseChatId,
					messageResponse.id,
					{
						text: messageResponse.message,
						isLastMessage: isLast,
						mediaResponse,
					}
				);
			}

			handleSetContext([...newContext], true);

			isMultipleMessages = false;
		} catch (error: any) {
			console.error(error);
			setIsSendError(true);
		} finally {
			if (isLast) {
				setIsTyping(false);
				focusInput();
			}
		}
	};

	const fetchMessage = async (
		contextForRequest: Message[],
		newContext: Message[],
		retryData?: {isRetry?: boolean; index?: number}
	): Promise<Message | null> => {
		try {
			setIsSendError(false);
			setIsTyping(true);
			const reqStartTime = Date.now();

			const res = await chatV3(user, {
				bot,
				context: contextForRequest,
				skipLimit: false,
				prevResponses: prevResponses.current,
				isRetry: retryData?.isRetry,
				llm: selectedLlm,
			});
			messagesCount.current++;
			increaseCountMessagesWithoutVote();
			const reqTime = Math.floor((Date.now() - reqStartTime) / 1000);

			if (!res.response && isMultipleMessages) {
				return null;
			}

			let message = prepareMessageFromResponse(res);
			if (prevResponses.current?.length) {
				message.prevResponses = prevResponses.current;
			}

			if (!isMuted && message && !message.video) {
				await handlePlayAudio(message);
				setIsTyping(false);
			}

			if (retryData?.index !== undefined) {
				newContext[retryData.index] = message;
				//TODO: replace old message in frb sklv
			} else {
				if (user && message) {
					message = await saveMessageToFrb(bot, user, message);
				}
				newContext.push(message);
				setLastResponse(res);
			}
			if (!retryData?.isRetry) {
				webEventsApi.message({
					chat_id: bot.id,
					bot_name: bot.attributes.name,
					chat_type: getChatType(bot),
					response_time: reqTime,
					message_type: res.is_user_msg_sexting ? 'sexting' : 'common',
					is_photo: !!message.image,
					split_response: res.split,
					contains_photo_request: res.contains_photo_request,
					user_messages: chatStorage.messageCount,
					chat_session_messages: messagesCount.current,
					blured: message.blured || false,
					media_type: !!res.media_response?.media_type
						? res.media_response.media_type === 'image'
							? 'photo'
							: 'video'
						: null,
					ultra_llm_id: selectedLlm?.id || null,
					ultra_llm_name: selectedLlm?.name || null,
				});
			}

			handleSetContext([...newContext]);

			isMultipleMessages = false;

			return message;
		} catch (error: any) {
			console.error(error);
			setIsSendError(true);
			enqueueSnackbar('Failed to send message', {variant: 'error'});
			return null;
		} finally {
			if (!isMultipleMessages) {
				setIsTyping(false);
				focusInput();
			}
		}
	};

	const handleDeleteMessage = async (message: Message) => {
		if (!message.id) {
			return;
		}
		await usersApi
			.deleteMessage(bot.attributes.firebaseChatId, message.id)
			.catch((e) => {
				enqueueSnackbar('Failed to delete message', {variant: 'error'});
				console.error(e);
			});

		chatStorage.deleteMessage(bot.id, message);
		handleSetContext(chatStorage.get(bot.id));
	};

	const handleVoteMessage = async (message: Message, reaction: string) => {
		if (!message.id) {
			return;
		}
		await usersApi
			.updateMessage(bot.attributes.firebaseChatId, message.id, {
				...message,
				text: message.message,
				reaction,
			})
			.catch((e) => {
				enqueueSnackbar('Failed to vote message', {variant: 'error'});
				console.error(e);
			});
	};

	const handleSendMessage = async (
		text: string,
		contextForFn = context
	): Promise<Message | null> => {
		try {
			handleCloseRetryFeedback();
			resetAudio();
			if (!user || (user.isAnon && chatStorage.isWithoutAuthLimitReached())) {
				setIsLoginOpened(true);
				return null;
			}

			if (!text.trim() || isInitLoading || isChatLimited) {
				return null;
			}
			prevResponses.current = [];
			chatStorage.message();
			if (chatStorage.isLimitReached() && !user?.isPaid) {
				setIsChatLimited(true);
				setIsLimitModalOpened(true);
			}
			if (user?.isAnon && chatStorage.isWithoutAuthLimitReached()) {
				setIsLoginOpened(true);
			}

			setInputHeight(inputInitialHeight);
			isInitResize = true;

			setInputValue('');

			const contextForRequest = contextForFn.slice(CONTEXT_SLICE_LENGTH);

			let newMessageForSend: Message = {
				message: text,
				turn: 'user',
				timestamp: Date.now(),
			};
			if (
				isTyping &&
				contextForFn.length > 1 &&
				contextForFn[context.length - 1]?.turn === 'user'
			) {
				abortFetch();
				let prevUserMessages = '';
				for (let i = contextForFn.length - 1; i >= 0; i--) {
					if (contextForFn[i].turn === 'bot') {
						break;
					}
					contextForRequest.pop();
					prevUserMessages = `${contextForFn[i].message} ${prevUserMessages}`;
				}

				newMessageForSend = {
					message: `${prevUserMessages} ${text}`,
					turn: 'user',
					timestamp: Date.now(),
				};
				isMultipleMessages = true;

				//TODO: replace old message in frb
			}

			const copy = contextForFn.slice();
			let newMessageFromUser: Message = {
				message: text,
				turn: 'user',
				timestamp: Date.now(),
			};

			if (user) {
				newMessageFromUser = await saveMessageToFrb(
					bot,
					user,
					newMessageFromUser
				);
			}

			const newContext = [...copy, newMessageFromUser];
			contextForRequest.push(newMessageForSend);
			handleSetContext(newContext);

			return fetchMessage(contextForRequest, newContext);
		} catch (e) {
			enqueueSnackbar('Failed to send message', {variant: 'error'});
			return null;
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (isChatLimited) {
			return;
		}
		setInputValue(replaceMultipleNewlines(e.target.value).slice(0, 500));
	};

	useEffect(() => {
		if (!inputValue.length) {
			setInputHeight(inputInitialHeight);
		}
	}, [inputValue]);

	const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
		if (isChatLimited) {
			return;
		}

		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault();
			handleSendMessage(inputValue);
		}

		if (e.key === 'Backspace' && inputValue.length) {
			//Fix input height if user delete symbol and rows count decreased
			const inputEl = inputRef.current;
			if (!inputEl) {
				return;
			}
			if (inputEl?.clientHeight !== inputEl?.scrollHeight) {
				return;
			}
			inputEl.style.height = inputEl.clientHeight - inputInitialHeight + 'px';
			if (inputEl.clientHeight < inputEl?.scrollHeight) {
				inputEl.style.height = inputEl?.scrollHeight - 4 + 'px';
			} else {
				setInputHeight(inputEl.clientHeight - inputInitialHeight);
			}
		}
	};

	useEffect(() => {
		if (!inputValue.trim().length && inputHeight > inputInitialHeight * 1.5) {
			setInputHeight(inputInitialHeight);
		}
	}, [inputValue]);

	useEffect(() => {
		if (inputRef.current && inputValue.length) {
			setInputHeight(
				Math.min(inputRef.current.scrollHeight - 4, inputMaxHeight)
			);
		}
	}, [inputRef.current?.scrollHeight]);

	useEffect(() => {
		if (chatRef?.current) {
			focusInput();
			scrollChat();
		}
	}, [isTyping, context]);

	const changeScrollForChat = () => {
		return;
		// if (!chatRef.current) {
		// 	return;
		// }

		// if (context.length === 0) {
		// 	chatRef.current.style.display = 'flex';
		// 	return;
		// }

		// const messageEl = chatRef.current.firstChild as HTMLElement;

		// const y = messageEl.getBoundingClientRect().y;
		// const wasFlex = chatRef.current.style.display === 'flex';
		// chatRef.current.style.display = 'block';
		// y < 70 || isMobileDevice() ? 'block' : 'flex';

		// if (wasFlex && chatRef.current.style.display === 'block') {
		// scrollChat();
		// }
	};

	useEffect(() => {
		chatStorage.setMessages(bot.id, context);

		if (needRefresh.current) {
			fetchInitChat();
			needRefresh.current = false;
		}

		changeScrollForChat();
		if (!chatRef.current) {
			return;
		}

		// if (initRef.current && context.length) {
		// 	const images = chatRef.current.querySelectorAll(
		// 		'.chat__message_bot-image'
		// 	);

		// 	images.forEach((image) => {
		// 		const img = image as HTMLImageElement;
		// 		img.onload = () => {
		// 			requestAnimationFrame(() => {
		// 				setTimeout(() => {
		// 					scrollChat();
		// 					changeScrollForChat();
		// 					img.onload = null;
		// 				}, 500);
		// 			});
		// 		};
		// 	});
		// }
		if (!isTyping) {
			initRef.current = false;
		}

		scrollChat();

		const lastMessageEl = chatRef.current.lastElementChild?.querySelector(
			'.chat__message_bot-image'
		) as HTMLImageElement | null;
		if (lastMessageEl) {
			lastMessageEl.onloadeddata = () => {
				setTimeout(() => {
					scrollChat();
					changeScrollForChat();
					lastMessageEl.onload = null;
				}, 500);
			};
		}

		const lastVideoMessageEl = chatRef.current.lastElementChild?.querySelector(
			'video'
		) as HTMLVideoElement | null;
		if (lastVideoMessageEl) {
			lastVideoMessageEl.onloadedmetadata = () => {
				setTimeout(() => {
					scrollChat();
				}, 500);
			};
		}
	}, [context]);

	const scrollChat = () => {
		if (chatRef.current && !noScrollRef.current) {
			chatRef.current.scrollTo({
				top: chatRef.current.scrollHeight + 1000,
				behavior: 'smooth',
			});
			const actionsRow = document.querySelector('.actions-row__item');
			if (actionsRow) {
				actionsRow.scrollIntoView({behavior: 'smooth', block: 'end'});
			}
		}
	};

	useEffect(() => {
		const secondMagicConst = isInitResize ? 0 : 0; //TODO: check
		setChatHeight(
			window.innerHeight -
				headerHeight -
				inputHeight -
				magicConst +
				secondMagicConst
		);
		isInitResize = false;
	}, [inputHeight]);

	useEffect(() => {
		const handleResize = () => {
			setChatHeight(
				window.innerHeight - headerHeight - inputHeight - magicConst
			);
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [inputHeight]);

	const clearChat = async () => {
		setIsGlobalLoading(true);
		await usersApi
			.clearChat(bot.attributes.firebaseChatId)
			.catch((e) => {
				enqueueSnackbar('Failed to clear chat', {variant: 'error'});
				console.error(e);
			})
			.catch((error) => {
				enqueueSnackbar('Failed to clear chat', {variant: 'error'});
				console.error(error);
			})
			.finally(() => {
				setIsGlobalLoading(false);
			});

		webEventsApi.historyDeleted({
			chat_id: bot.id,
			chat_type: getChatType(bot),
			bot_name: bot.attributes.name,
			split_response: getSplit(context),
		});
		handleSetContext([]);
		initRef.current = true;
		needRefresh.current = true;
	};

	const deleteChat = async () => {
		await usersApi.deleteChat(bot.attributes.firebaseChatId).catch((e) => {
			enqueueSnackbar('Failed to delete chat', {variant: 'error'});
			console.error(e);
		});

		webEventsApi.chatDeleted({
			chat_id: bot.id,
			chat_type: getChatType(bot),
			bot_name: bot.attributes.name,
			split_response: getSplit(context),
		});
		chatStorage.deleteChat(bot.id.toString());
		handleSetContext([]);
	};

	const deleteBot = async () => {
		await webApi.deleteBot(bot.id).catch((e) => {
			enqueueSnackbar('Failed to delete bot', {variant: 'error'});
			console.error(e);
		});

		webEventsApi.chatDeleted({
			chat_id: bot.id,
			chat_type: getChatType(bot),
			bot_name: bot.attributes.name,
			split_response: getSplit(context),
		});
		chatStorage.deleteChat(bot.id.toString());
		handleSetContext([]);
	};

	//audio
	const hasVoice = !!bot.attributes.voice;

	const resetAudio = () => {
		if (audio) {
			audio.pause();
			setAudio(null);
		}
		if (isPlayingAudio) {
			setIsPlayingAudio(false);
		}
	};

	const handlePlayAudio = async (message: Message) => {
		if (playInitMessage) {
			setPlayInitMessage(null);
		}

		if (isMuted) {
			return;
		}
		const text = message.message;
		const voice = bot.attributes.voice?.data?.attributes.name;
		if (!text || !voice) {
			return;
		}

		if (!user?.isPaid) {
			handleLoginOrPaywall();
			return;
		}

		setIsPlayingAudio(true);
		try {
			const blob = await api.generateSpeech(text, voice);
			if (!blob) {
				setIsPlayingAudio(false);
				enqueueSnackbar('Failed to generate audio', {variant: 'error'});
				return;
			}

			const objectURL = URL.createObjectURL(blob);
			const audio = new Audio(objectURL);
			setAudio(audio);
			audioForce = audio;
			audio.play();
			audio.onended = () => {
				setIsPlayingAudio(false);
			};
		} catch (error: any) {
			setIsPlayingAudio(false);
			enqueueSnackbar('Failed to generate audio', {variant: 'error'});
		}
	};

	const handleStopAudio = () => {
		if (!audio) {
			return;
		}
		audio?.pause();
		setIsPlayingAudio(false);
	};

	useLayoutEffect(() => {
		return () => {
			handleStopAudio();
			audioForce?.pause();
			audioForce = null;
		};
	}, []);
	//audio end

	//count messages without vote
	const increaseCountMessagesWithoutVote = () => {
		setCountMessagesWithoutVote(countMessagesWithoutVote + 1);
	};
	//end count messages without vote

	const handleMagic = async (
		message: Message,
		retryData?: {prevContext: Message[]; prevImageMessage: Message}
	) => {
		const usedContext = retryData?.prevContext || context;
		if (!user?.isPaid) {
			handleLoginOrPaywall();
			return;
		}

		try {
			setIsTyping(true);

			const reqStartTime = Date.now();

			webEventsApi.magicPhotoRequested({
				chat_id: bot.id,
				chat_type: getChatType(bot),
				bot_name: bot.attributes.name,
				split_response: getSplit(usedContext),
			});
			const res = await api.getContextualImage(bot.id.toString(), usedContext);
			if (!res || !res.media_url) {
				enqueueSnackbar('Failed to generate image', {variant: 'error'});
				return;
			}

			webEventsApi.magicPhotoGenerated({
				chat_id: bot.id,
				chat_type: getChatType(bot),
				bot_name: bot.attributes.name,
				split_response: getSplit(usedContext),
				generation_time: Date.now() - reqStartTime,
			});
			let newMessage: Message = {
				message: '',
				turn: 'bot',
				timestamp: Date.now(),
				image: res.media_url,
				media_response: {
					media_id: res.media_id,
					media_type: res.media_type,
					media_url: res.media_url,
					media_resolution: res.media_resolution,
					prompt: res.prompt,
					nsfw: res.is_nsfw,
				},
				split: message.split,
			};

			if (retryData?.prevImageMessage) {
				await usersApi.updateMessage(
					bot.attributes.firebaseChatId,
					retryData.prevImageMessage.id!,
					{
						mediaResponse: {
							mediaId: res.media_id,
							mediaType: res.media_type,
							mediaUrl: res.media_url,
							mediaResolution: res.media_resolution,
						},
						isLastMessage: true,
						text: '',
					}
				);
				newMessage.id = retryData.prevImageMessage.id;
			} else {
				newMessage = await saveMessageToFrb(bot, user, newMessage);
			}

			handleSetContext([...usedContext, newMessage]);
		} catch (e) {
			console.error(e);
			enqueueSnackbar('Failed to generate image', {variant: 'error'});
		} finally {
			setIsTyping(false);
		}
	};

	const handleCopy = (message: Message) => {
		enqueueSnackbar('Messageopied to clipboard', {variant: 'success'});
		navigator.clipboard.writeText(message.message);
	};

	return {
		chatHeight,
		inputHeight,
		chatRef,
		inputRef,
		isTyping,
		context,
		handleInputChange,
		handleKeyDown,
		scrollChat,
		inputValue,
		handleSendMessage,
		handleRetry,
		clearChat,
		deleteChat,
		deleteBot,
		deleteMessage: handleDeleteMessage,
		voteMessage: handleVoteMessage,
		lastResponse,
		isInitLoading,
		isChatLimited,
		limitTimeLeft,
		setContext: handleSetContext,
		handleEdit,
		handlePlayAudio: hasVoice && isPlayingAudio ? undefined : handlePlayAudio,
		handleStopAudio: hasVoice && isPlayingAudio ? handleStopAudio : undefined,
		isSendError,
		handleResend,
		shouldShowRetryFeedback,
		handleCloseRetryFeedback,
		handleOpenRetryFeedback,
		handleCloseVoteNotification,
		shouldShowVoteNotification,
		availableLlms,
		selectedLlm,
		setSelectedLlm,
		handleMagic,
		isMuted,
		handleSetIsMuted: handleSetIsMutedHandler,
		isGlobalLoading,
		setIsGlobalLoading,
		handleCopy,
		handleUpdateMessageInContextByMediaId,
		pagination: {
			hasMore: pagination.hasMore,
			loading: pagination.loading,
			onLoadMore: handleLoadMoreChat,
		},
	};
};
