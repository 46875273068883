import './ChatMain.css';
import like from './images/like.svg';
import dislike from './images/dislike.svg';
import retry from './images/retry.svg';
import liked from './images/liked.svg';
import disliked from './images/disliked.svg';
import edit from './images/edit.svg';
import play from './images/play.svg';
import pause from './images/pause.svg';
import copy from './images/copy.svg';
import closeNotifyImg from './images/close-notify.svg';
import badMemoryImg from './images/badMemory.webp';
import badPhotoImg from './images/badPhoto.webp';
import ignoringImg from './images/ignoring.webp';
import outOfCharacter from './images/outOfCharacter.webp';
import repetitiveImg from './images/repetitive.webp';
import boringImg from './images/boring.webp';
import feedbackImg from './images/writeFeedback.webp';
import magicImg from './images/magic.svg';
import {useEffect, useState} from 'react';
import cn from 'classnames';
import {DislikeFeebackModal} from './FeedbackModal/FeedbackModal';
import {Message} from '../../../api/Api';
import {Actions} from './ChatMain.types';

export const ActionsRow = ({
	actions = {
		muted: true,
		copy: () => {},
	},
	turnEditMode,
	action: parentAction = null,
	message,
	nomargin = false,
	handleRetry,
}: {
	actions?: Actions;
	turnEditMode?: () => void;
	action?: 'like' | 'dislike' | 'retry' | null;
	message: Message;
	nomargin?: boolean;
	handleRetry?: Actions['retry'];
}) => {
	const [action, setAction] = useState<'like' | 'dislike' | 'retry' | null>(
		parentAction
	);
	const [showDislikeTypePopover, setShowDislikeTypePopover] = useState(false);
	const [isFeedbackModalOpened, setIsFeedbackModalOpened] = useState(false);

	const handleSubmitDislike = (type: string) => {
		actions.dislike?.({description: type, message});
		setShowDislikeTypePopover(false);
	};

	const handleClosePopover = (e: MouseEvent) => {
		//@ts-ignore
		if (
			!(e.target as HTMLElement)?.closest('.actions-row__dilike-selector') &&
			!(e.target as HTMLElement)?.classList?.contains('actions-row__item')
		) {
			setShowDislikeTypePopover(false);
			actions.dislike?.({message});
		}
	};

	const handleSubmitFeedback = (data: {text: string; email: string}) => {
		setIsFeedbackModalOpened(false);
		setShowDislikeTypePopover(false);
		actions.dislike?.({
			text: data.text,
			email: data.email,
			description: 'leave_feedback',
			message,
		});
	};

	useEffect(() => {
		if (showDislikeTypePopover) {
			document.addEventListener('click', handleClosePopover);
		} else {
			document.removeEventListener('click', handleClosePopover);
		}
		return () => {
			document.removeEventListener('click', handleClosePopover);
		};
	}, [showDislikeTypePopover]);

	const handleClick = async (
		actionName: 'like' | 'dislike' | 'retry' | 'edit'
	) => {
		switch (actionName) {
			case 'like':
				setAction('like');
				const res = await actions.like?.(message);
				if (!res) {
					setAction(null);
				}
				break;
			case 'dislike':
				if (actions.disabledLike) {
					actions.dislike?.({description: 'dislike', message});
					return;
				}
				setShowDislikeTypePopover(true);
				setAction('dislike');
				break;
			case 'retry':
				if (handleRetry) {
					handleRetry(message);
				} else {
					actions.retry?.(message);
				}
				break;
			case 'edit':
				turnEditMode?.();
				break;
		}
	};

	const getLikeBtn = () => {
		if (!action) {
			return (
				<img
					src={like}
					alt="like"
					className="actions-row__item"
					onClick={() => handleClick('like')}
				/>
			);
		}
		if (action === 'like') {
			return <img src={liked} alt="like" className="actions-row__item" />;
		}
		return null;
	};

	const getDislikeBtn = () => {
		if (!action) {
			return (
				<img
					src={dislike}
					alt="dislike"
					className="actions-row__item"
					onClick={() => handleClick('dislike')}
				/>
			);
		}
		if (action === 'dislike') {
			return <img src={disliked} alt="dislike" className="actions-row__item" />;
		}
		return null;
	};

	const getFeedbackRow = (img: string, text: string, action: () => void) => (
		<div className="dilike-selector__row" onClick={action}>
			<img className="dilike-selector__img" src={img} alt={text} />
			<p className="dilike-selector__text">{text}</p>
		</div>
	);

	const handleCopy = (message: Message) => {
		actions.copy?.(message);
	};

	return (
		<>
			{actions?.shouldShowVoteNotification && (
				<div className="actions__layout"></div>
			)}
			<div
				className={cn('actions-row', {
					'actions-row_nomargin': nomargin,
				})}
			>
				{actions?.shouldShowVoteNotification && (
					<div className="actions-notify">
						<p className="actions-notify__text">
							You can improve my artificial intellegence by using the{' '}
							<span>“Like”</span> and <span>“Dislike”</span> buttons!
						</p>
						<img
							className="actions-notify__close"
							src={closeNotifyImg}
							alt="close-notify"
							onClick={actions.handleCloseVoteNotification}
						/>
					</div>
				)}
				{showDislikeTypePopover && (
					<div className="actions-row__dilike-selector">
						{getFeedbackRow(ignoringImg, 'Ignoring my request', () =>
							handleSubmitDislike('ignoring_request')
						)}
						{getFeedbackRow(outOfCharacter, 'Out of character', () =>
							handleSubmitDislike('out_of_character')
						)}
						{getFeedbackRow(badMemoryImg, 'Bad memory', () =>
							handleSubmitDislike('bad_memory')
						)}
						{getFeedbackRow(boringImg, 'Boring', () =>
							handleSubmitDislike('boring')
						)}
						{getFeedbackRow(repetitiveImg, 'Repetitive', () =>
							handleSubmitDislike('repetitive')
						)}
						{getFeedbackRow(badPhotoImg, 'Bad photo', () =>
							handleSubmitDislike('bad_photo')
						)}
						<div
							className="dilike-selector__row"
							onClick={() => setIsFeedbackModalOpened(true)}
						>
							<img
								className="dilike-selector__img"
								src={feedbackImg}
								alt="Write feedback"
							/>
							<p className="dilike-selector__text">Write feedback</p>
						</div>
					</div>
				)}
				{actions.like && getLikeBtn()}
				{actions.dislike && getDislikeBtn()}
				{actions.retry && (
					<img
						src={retry}
						alt="retry"
						className={cn('actions-row__item', {
							'actions-row__item_opacity': actions?.shouldShowVoteNotification,
						})}
						onClick={
							actions?.shouldShowVoteNotification
								? () => {}
								: () => handleClick('retry')
						}
					/>
				)}
				{actions.copy && (
					<img
						src={copy}
						alt="copy"
						className="actions-row__item"
						onClick={() => handleCopy(message)}
					/>
				)}
				{actions.isEditable && (
					<img
						src={edit}
						alt="edit"
						className={cn('actions-row__item', {
							'actions-row__item_opacity': actions?.shouldShowVoteNotification,
						})}
						onClick={
							actions?.shouldShowVoteNotification
								? () => {}
								: () => handleClick('edit')
						}
					/>
				)}
				{actions.playAudio && !actions.muted && (
					<img
						src={play}
						alt="play"
						className={cn('actions-row__item', {
							'actions-row__item_opacity': actions?.shouldShowVoteNotification,
						})}
						onClick={
							actions?.shouldShowVoteNotification
								? () => {}
								: () => actions.playAudio?.(message)
						}
					/>
				)}
				{actions.stopAudio && !actions.muted && (
					<img
						src={pause}
						alt="pause"
						className="actions-row__item"
						onClick={actions.stopAudio}
					/>
				)}
				{actions.magic && (
					<img
						src={magicImg}
						alt="Magic"
						className="actions-row__item"
						onClick={() => actions.magic?.(message)}
					/>
				)}
			</div>
			{isFeedbackModalOpened && (
				<DislikeFeebackModal
					open={isFeedbackModalOpened}
					onClose={() => setIsFeedbackModalOpened(false)}
					onSubmit={handleSubmitFeedback}
					onCloseByUser={() => actions.dislike?.({message})}
				/>
			)}
		</>
	);
};
