import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';
import {LlmData} from '../../../../../common-lib/src/api/Api.types';
import llmImg from './images/llm.svg';
import arrowImg from './images/arrow.svg';
import checkImg from './images/check.svg';
import lockImg from './images/lock.svg';
import styles from './LlmSelector.module.css';

type Props = {
	llms: LlmData[];
	selectedLlm: LlmData | null;
	handleSelectLlm: (llm: LlmData | null) => void;
};

export const LlmSelector = ({llms, selectedLlm, handleSelectLlm}: Props) => {
	const [opened, setOpened] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const [isBottom, setIsBottom] = useState(false);

	const handleCloseLlmSelector = (e: MouseEvent) => {
		if (
			!(e.target as HTMLElement)?.closest('.actions-row__llm') &&
			!(e.target as HTMLElement)?.classList?.contains('llm-selector__row')
		) {
			setOpened(false);
		}
	};

	useEffect(() => {
		if (opened) {
			document.addEventListener('click', handleCloseLlmSelector);
		} else {
			document.removeEventListener('click', handleCloseLlmSelector);
		}
		return () => {
			document.removeEventListener('click', handleCloseLlmSelector);
		};
	}, [opened]);

	useEffect(() => {
		if (!containerRef.current || !opened) {
			setIsBottom(false);
			return;
		}

		const rect = containerRef.current.getBoundingClientRect();
		setIsBottom(rect.y < 0);
	}, [opened]);

	if (!llms?.length) {
		return null;
	}

	const defaultLlm = llms.find((llm) => llm.available);
	const selected = selectedLlm || defaultLlm;
	const isSelectedBasic = selected?.access_level === 'basic';
	const name = isSelectedBasic ? 'Basic' : selected?.name;

	const handleClick = () => {
		setOpened(!opened);
	};

	const handleSelect = (llm: LlmData | null) => {
		handleSelectLlm(llm);
	};

	return (
		<div
			className={cn('actions-row__llm', styles.actionsRowLlm)}
			onClick={handleClick}
		>
			<img src={llmImg} alt="LLM" />
			<p className={styles.actionsRowLlmName}>{name}</p>
			<img src={arrowImg} alt="open" className={styles.actionsRowLlmArrow} />
			{opened && (
				<div
					className={cn(styles.actionsRowLlmSelector, {
						[styles.actionsRowLlmSelectorBottom]: isBottom,
					})}
					ref={containerRef}
				>
					{llms.map((llm) => (
						<div
							className={cn(styles.llmSelectorRow, 'llm-selector__row')}
							onClick={() => handleSelect(llm)}
							key={llm.id}
						>
							<img
								src={llm.logo_url}
								alt={llm.name}
								className={styles.llmSelectorImg}
							/>
							<p className={styles.llmSelectorText}>{llm.name}</p>
							{llm.id === selected?.id && (
								<img
									src={checkImg}
									alt="check"
									className={styles.llmSelectorCheck}
								/>
							)}
							{!llm.available && (
								<img
									src={lockImg}
									alt="lock"
									className={styles.llmSelectorLock}
								/>
							)}
						</div>
					))}
				</div>
			)}
		</div>
	);
};
